// Packages
import { Link, useMatch, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

// Components

// API

// CSS
import './Topbar.scss';

const Topbar = () => {
    let location = useLocation();
    let currentTab = location.pathname;

    return (
        <Navbar className="topbar" expand="lg">
            <div className="topbar__brand">
                <div className="logo__wrapper">
                    <div className="topbar__logo"></div>
                    <a className="topbar__text" href="/">
                        <span>Accel Prime</span><span>Technologies Inc.</span>
                    </a>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Item>
                        <Link className={currentTab === '/' ? "nav-link active" : "nav-link"} to="/">Home</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className={currentTab === '/about-us' ? "nav-link active" : "nav-link"} to="/about-us">About Us</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className={currentTab === '/news' ? "nav-link active" : "nav-link"} to="/news">News</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className={currentTab === '/products-services' ? "nav-link active" : "nav-link"} to="/products-services">Products & Services</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className={currentTab === '/locations' ? "nav-link active" : "nav-link"} to="/locations">Locations</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className={currentTab === '/contact-us' ? "nav-link active" : "nav-link"} to="/contact-us">Contact Us</Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
};

export default Topbar;
// Packages
import { Col } from 'react-bootstrap';/*  */

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';

// API

// CSS
import './LocationsBanner.css';

const LocationsBanner = () => {
    return (

        <MainContent>
            <Col>
                <div className="locations__banner">
                    <span className="locations__title">Book an appointment on our nearest service center near you.</span>
                </div>
            </Col>
        </MainContent>
    );
};

export default LocationsBanner;
// Packages
import { useState } from 'react';
import { Col, Button } from 'react-bootstrap';

// Components
import MainContent from '../../UI/MainContent/MainContent';
import Featured from '../../UI/Featured/Featured';

// API

// CSS
import './FeaturedPartners.css';

const FeaturedPartners = (props) => {
    const [initialPartners, setInitialPartners] = useState(5);
    const { partners } = props;

    const showMoreHandler = () => {
        setInitialPartners(partners.length);
    };

    const viewLessHandler = () => {
        setInitialPartners(5);
    };

    return (
        <MainContent>
            <Col className="p-0">
                <Featured title="Our featured partners">
                    {partners && partners.slice(0, initialPartners).map(partner => {
                        return <div className="featured__partner" key={partner.id} style={{ backgroundImage: `url('${partner.image_url_path}')` }}></div>;
                    })}
                    {(partners && partners.length >= initialPartners) && (
                        <div className="featured__wrapper">
                            <Button className="featured__button" size="lg" onClick={() => {
                                if (partners.length === initialPartners) {
                                    viewLessHandler();
                                } else { showMoreHandler(); }
                            }}>{partners && partners.length === initialPartners ? 'View Less' : 'Show more'}</Button>
                        </div>
                    )}
                </Featured>
            </Col>
        </MainContent >
    );
};

export default FeaturedPartners;
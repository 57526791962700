// Packages
import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Container } from "react-bootstrap";

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from "../../components/Layout/Layout/Layout";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import NewsList from './NewsList/NewsList';

// API
import { getAllNews } from "../../api/request";

// CSS
import "./NewsPage.css";

const NewsPage = () => {
  const [news, setNews] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!news) dataNews();
  }, []);

  const dataNews = async () => {
    const data = await getAllNews();
    setNews(data);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Fragment>
      <Helmet>
        <title>News</title>
      </Helmet>
      {isLoading && <LoadingScreen />}
      {!isLoading && <Layout>
        <Container fluid>
          <PageHeader title="Press Release & Recent News" />
          <NewsList title="Recent News" news={news} />
          <NewsList title="National News" news={news} />
        </Container>
      </Layout>}
    </Fragment>
  );
};

export default NewsPage;

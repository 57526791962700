// Packages
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

// Components
import FormNewsLetter from '../../Form/FormNewsletter/FormNewsletter';

// API

// CSS
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__body">
                <div className="footer__column">
                    <span className="column__title">Quick Links</span>
                    <div className="column__body">
                        <a className="column__link" href="/">
                            <span>Home</span>
                        </a>
                        <a className="column__link" href="/about-us">
                            <span>About Us</span>
                        </a>
                        <a className="column__link" href="/news">
                            <span>News</span>
                        </a>
                        <a className="column__link" href="/products-services">
                            <span>Services</span>
                        </a>
                        <a className="column__link" href="/locations">
                            <span>Locations</span>
                        </a>
                        <a className="column__link" href="/contact-us">
                            <span>Contact Us</span>
                        </a>
                    </div>
                </div>
                <div className="footer__column">
                    <span className="column__title">For technical concerns</span>
                    <div className="column__body">
                        <div className="d-flex flex-column">
                            <div className="column__link">
                                <span>technical@apti.com.ph</span>
                            </div>
                            <div className="column__link">
                                <span>apti.technical@gmail.com</span>
                            </div>
                        </div>
                    </div>
                    <span className="column__title">For sales & marketing concerns</span>
                    <div className="column__body">
                        <div className="d-flex flex-column">
                            <div className="column__link">
                                <span>marketing@apti.com.ph</span>
                            </div>
                            <div className="column__link">
                                <span>accelprime@apti.com.ph</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__column">
                    <span className="column__title">Location</span>
                    <div className="column__body">
                        <div className="column__link column__link--address">
                            <span>Unit 405 PM Building #26 N.Domingo Street, Barangay Valencia, Quezon City, Metro Manila, Philippines</span>
                        </div>
                    </div>
                    <span className="column__title">Contact No.</span>
                    <div className="column__body">
                        <div className="column__link">
                            <span>Telephone: 8-721-5833 to 35</span>
                        </div>
                        <div className="column__link">
                            <span>Fax Number: 8-721-0052</span>
                        </div>
                    </div>
                    <span className="column__title">Social Media</span>
                    <div className="column__body">
                        <a className="column__link" href="https://www.facebook.com/APTI2012" target="_blank" rel="noreferrer">
                            <span>Facebook</span>
                        </a>
                    </div>
                </div>
                <FormNewsLetter />
            </div >
            <div className="footer__copyright">
                <span className="copyright__text">Copyright © 2022 APTI. All Rights Reserved.</span>
            </div>
        </footer >
    );
};

export default Footer;
// Packages
import { Fragment, useState, useEffect } from 'react';
import { Col, Pagination } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';
import NewsCard from "../NewsCard/NewsCard";
import convertDate from "../../../helpers/convertDate";

// API
import { getAllNews } from "../../../api/request";

// CSS
import './NewsList.css';

const NewsList = (props) => {
    const [news, setNews] = useState("");
    const [active, setActive] = useState(1);
    const [paginationTotal, setPaginationTotal] = useState([]);

    useEffect(() => {
        if (!news) {
            dataNews();
        }
    }, [news]);

    const dataNews = async (active) => {
        const data = await getAllNews(active);
        setNews(data);
        setPaginationTotal(data.meta.last_page);
        setActive(active !== undefined ? active : 1);
    };

    // const changePage = async (event) => {
    //     const data = await getAllNews(event.target.value);
    //     setNews(data);
    //     setPaginationTotal(data.meta.last_page);
    //     setActive(active !== undefined ? event.target.value : 1);
    // };

    // const firstPage = async () => {
    //     setActive(1);
    //     const data = await getAllNews(1);
    //     setNews(data);
    // };

    // const lastPage = async () => {
    //     setActive(news.meta.last_page);
    //     const data = await getAllNews(news.meta.last_page);
    //     setNews(data);
    // };

    const prevPage = async () => {
        setActive(active !== 1 ? active - 1 : 1);
        const data = await getAllNews(active !== 1 ? active - 1 : 1);
        setNews(data);
    };

    const nextPage = async () => {
        setActive(active !== news.meta.last_page ? active + 1 : news.meta.last_page);
        const data = await getAllNews(active !== news.meta.last_page ? active + 1 : news.meta.last_page);
        setNews(data);
    };

    const renderPagination = () => {
        let pagination = [];
        for (let i = 1; i <= paginationTotal; i++) {
            pagination.push(
                <Pagination.Item key={i} active={i === active} onClick={() => { dataNews(i); }} >
                    {i}
                </Pagination.Item >
            );
        }
        return pagination;
    };

    return (
        <Fragment>
            <MainContent>
                <Col className="p-0">
                    <div className="news_list">
                        <span className="news_list__title">{props.title}</span>
                        <div className="news_list__body">
                            {news && news.data.map((item) => {
                                const convertedDate = convertDate(item.created_at);
                                return (
                                    <NewsCard
                                        key={item.id}
                                        id={item.id}
                                        title={item.title}
                                        date={convertedDate}
                                        image={item.news_image_url_path}
                                    />
                                );
                            })}
                            {!news && (
                                <div className="news_list__placeholder">
                                    <span className="text-black-50">No articles found.</span>
                                </div>
                            )}
                        </div>
                    </div>
                    {news &&
                        <Pagination>
                            {/* <Pagination.First onClick={() => firstPage()} /> */}
                            <Pagination.Prev onClick={() => prevPage()} />
                            {renderPagination()}
                            <Pagination.Next onClick={() => nextPage()} />
                            {/* <Pagination.Last onClick={() => lastPage()} /> */}
                        </Pagination>
                    }
                </Col>
            </MainContent>
        </Fragment>
    );
};

export default NewsList;
// Packages
import { Button } from "react-bootstrap";

// Components

// API

// CSS
import './SubscriptionOverlay.scss';

const SubscriptionOverlay = (props) => {
    return (
        <div className="subscriptionOverlay">
            <div className="message">
                <div className="header">
                    <svg x="0px" y="0px"
                        viewBox="0 0 310.277 310.277" >
                        <g>
                            <path d="M155.139,0C69.598,0,0,69.598,0,155.139c0,85.547,69.598,155.139,155.139,155.139
		c85.547,0,155.139-69.592,155.139-155.139C310.277,69.598,240.686,0,155.139,0z M144.177,196.567L90.571,142.96l8.437-8.437
		l45.169,45.169l81.34-81.34l8.437,8.437L144.177,196.567z"
                            />
                        </g>
                    </svg>
                    <span className="title">Thank you for your subscription!</span>
                </div>
                <p className="description">You've successfully subscribed to our newsletter. <br /> An email will be sent to you shortly.</p>
                <Button onClick={() => props.closeOverlayHandler()}>
                    <svg
                        viewBox="0 0 512 512" >
                        <g>
                            <g>
                                <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512
			512,452.922 315.076,256 		"/>
                            </g>
                        </g>
                    </svg>
                </Button>
            </div>
        </div>
    );
};

export default SubscriptionOverlay;
// Packages
import { Row, Col } from 'react-bootstrap';

// Components

// API

// CSS
import './ArticleBanner.css';

const ArticleBanner = (props) => {
    return (
        <Row className="article__landing">
            <Col lg="12" className="p-0">
                <div className="article__image" style={{ backgroundImage: `url(${props.image})` }}>
                    <div className="article__header">
                        <span className="article__title">{props.title}</span>
                        <div className="article__details">
                            <span>{props.date}</span>
                            <span>{props.author}</span>
                        </div>
                    </div>
                </div>
            </Col >
        </Row >
    );
};

export default ArticleBanner;
// Packages
import { Fragment, useState } from "react";
import { Col, Accordion } from "react-bootstrap";

// Components
import MainContent from "../../../components/UI/MainContent/MainContent";
import FormLocationSearch from "../FormLocationSearch/FormLocationSearch";
import LocationCard from "../LocationCard/LocationCard";

// API

// CSS
import "./LocationsList.css";

const LocationsList = (props) => {
  const { serviceCenters } = props;
  const [newServiceCenters, setNewServiceCenters] = useState(serviceCenters);
  const [active, setActive] = useState(false);

  // Search Location Filter
  const getSearchResult = (data) => {
    if (data.data && data.data.length !== 0) {
      setNewServiceCenters(data.data);
      setActive(true);
    } else if (data.error === "No service center found.") {
      setNewServiceCenters("");
      setActive(true);
    } else {
      setNewServiceCenters(serviceCenters);
    }
  };

  // Region I
  const arrayOne =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 1;
    });
  // Region II
  const arrayTwo =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 2;
    });
  // Region III
  const arrayThree =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 3;
    });
  // Region IVA
  const arrayFourA =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 4;
    });
  // Region IVB
  const arrayFourB =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 5;
    });
  // Region V
  const arrayFive =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 6;
    });
  // Region VI
  const arraySix =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 7;
    });
  // Region VII<LocationCard id="ncr" province="NCR" store={arrayNCR} />
  const arraySeven =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 8;
    });
  // Region VIII
  const arrayEight =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 9;
    });
  // Region IX
  const arrayNine =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 10;
    });
  // Region X
  const arrayTen =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 11;
    });
  // Region XI
  const arrayEleven =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 12;
    });
  // Region XII
  const arrayTwelve =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 13;
    });
  // Region XIII
  const arrayThirteen =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 14;
    });
  // NCR
  const arrayNCR =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 15;
    });
  // CAR
  const arrayCAR =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 16;
    });
  // BARMM
  const arrayBARMM =
    newServiceCenters.length > 0 &&
    newServiceCenters.filter((item) => {
      return item.region.id === 17;
    });

  return (
    <MainContent>
      <Col>
        <FormLocationSearch
          getSearchResult={getSearchResult}
          setActive={setActive}
        />
        <div className="locations_list">
          <Accordion defaultActiveKey="0">
            {!active && (
              <Fragment>
                <LocationCard id="ncr" province="NCR" store={arrayNCR} />
                <LocationCard id="car" province="CAR" store={arrayCAR} />
                <LocationCard id="barmm" province="BARMM" store={arrayBARMM} />
                <LocationCard id="1" province="Region I" store={arrayOne} />
                <LocationCard id="2" province="Region II" store={arrayTwo} />
                <LocationCard id="3" province="Region III" store={arrayThree} />
                <LocationCard
                  id="4a"
                  province="Region IV-A"
                  store={arrayFourA}
                />
                <LocationCard
                  id="4b"
                  province="Region IV-B"
                  store={arrayFourB}
                />
                <LocationCard id="5" province="Region V" store={arrayFive} />
                <LocationCard id="6" province="Region VI" store={arraySix} />
                <LocationCard id="7" province="Region VII" store={arraySeven} />
                <LocationCard
                  id="8"
                  province="Region VIII"
                  store={arrayEight}
                />
                <LocationCard id="9" province="Region IX" store={arrayNine} />
                <LocationCard id="10" province="Region X" store={arrayTen} />
                <LocationCard
                  id="11"
                  province="Region XI"
                  store={arrayEleven}
                />
                <LocationCard
                  id="12"
                  province="Region XII"
                  store={arrayTwelve}
                />
                <LocationCard
                  id="13"
                  province="Region XIII"
                  store={arrayThirteen}
                />
              </Fragment>
            )}

            {active &&
              newServiceCenters.length > 0 &&
              newServiceCenters.map((item) => {
                return (
                  <div key={item.id} className="locations__store">
                    <h5 className="locations_card__store">{item.store_name}</h5>
                    <div className="address__wrapper">
                      <svg viewBox="0 0 24 28.235">
                        <path
                          id="icons8_marker_1"
                          d="M19,4A11.992,11.992,0,0,0,9.889,23.8c.213.242,5.243,5.957,6.923,7.559a3.166,3.166,0,0,0,4.377,0c1.953-1.863,6.723-7.331,6.932-7.571A11.991,11.991,0,0,0,19,4Zm0,15.529A3.529,3.529,0,1,1,22.529,16,3.53,3.53,0,0,1,19,19.529Z"
                          transform="translate(-7 -4)"
                        />
                      </svg>
                      <span className="locations_card__address">
                        {item.address}
                      </span>
                    </div>
                    <div className="hours__wrapper">
                      <svg viewBox="0 0 24 24">
                        <path
                          id="icons8_clock"
                          d="M16,4A12,12,0,1,0,28,16,12.014,12.014,0,0,0,16,4Zm2.737,15.937a.9.9,0,0,1-1.273,0l-3-3A.9.9,0,0,1,14.2,16.3V9.7a.9.9,0,1,1,1.8,0v6.227l2.737,2.737A.9.9,0,0,1,18.737,19.937Z"
                          transform="translate(-4 -4)"
                        />
                      </svg>
                      <span className="locations_card__business_hours">
                        {item.office_hours}
                      </span>
                    </div>
                  </div>
                );
              })}

            {!newServiceCenters && (
              <p className="text-center p-4">No service centers found.</p>
            )}
          </Accordion>
        </div>
      </Col>
    </MainContent>
  );
};

export default LocationsList;

// Packages
import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from '../../components/Layout/Layout/Layout';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import ViewProduct from './ViewProduct/ViewProduct';

// API
import { getProduct } from '../../api/request';

// CSS
import './ViewProductPage.css';

const ViewProductPage = (props) => {
    const { id } = useParams();
    const [product, setProduct] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!product) dataProduct();
    }, []);

    const dataProduct = async () => {
        const data = await getProduct(id);
        setProduct(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <Fragment>
            <Helmet>
                <title>{`${product && product.product_name}`}</title>
            </Helmet>
            {isLoading && <LoadingScreen />}
            {!isLoading &&
                <Layout>
                    <Container fluid>
                        <PageHeader title="Products" />
                        <ViewProduct product={product} />
                    </Container>
                </Layout>}
        </Fragment>
    );
};

export default ViewProductPage;
// Packages
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';

// API

// CSS
import './Statement.css';

const Statement = (props) => {
    const [mission, setMission] = useState('');
    const [vision, setVision] = useState('');

    useEffect(() => {
        if (props) {
            const { mission, vision } = props;
            setMission(mission);
            setVision(vision);
        }
    }, [props]);

    return (
        <MainContent>
            <Col lg={12} className="p-0">
                <div className="statement__wrapper">
                    <div className="statement">
                        <div className="statement__body">
                            <span className="statement__title">Vision</span>
                            <p className="statement__description">{mission}</p>
                        </div>
                    </div>
                    <div className="statement">
                        <div className="statement__body">
                            <span className="statement__title">Mission</span>
                            <p className="statement__description">{vision}</p>
                        </div>
                    </div>
                </div>
            </Col>
        </MainContent>
    );
};

export default Statement;
// Packages
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components

// API

// CSS
import './NewsCard.css';

const NewsCard = (props) => {
    const [currentId, setCurrentId] = useState('');
    const [image, setImage] = useState('');
    const [articleDate, setArticleDate] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (props) {
            setCurrentId(props.id);
            setImage(props.image);
            setArticleDate(props.date);
            setTitle(props.title);
        }
    }, [props]);

    return (
        <div className="news_card">
            <a className="news_card__link" href={`/news/${currentId}`} rel="noreferrer">
            </a>
            <div className="news_card__image" style={{ backgroundImage: `url(${image})` }}></div>
            <div className="news_card__body">
                <div className="news_card__date">{articleDate}</div>
                <div className="news_card__title">{title}</div>
            </div>
        </div>
    );
};

export default NewsCard;
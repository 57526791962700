// Packages
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

// Components

// API

// CSS
import "./AboutUsBanner.css";

const AboutUsBanner = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (props) {
      const { title, description, image } = props;
      setTitle(title);
      setDescription(description);
      setImage(image);
    }
  }, [props]);

  return (
    <Row className="about__landing">
      <Col lg={6}>
        <div className="about__wrapper">
          <div className="about__video">
            <iframe
              src="https://www.youtube.com/embed/MhCHq10EUr0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </Col>
      <Col lg={6}>
        <div className="about__body">
          <span className="about__title">{title}</span>
          <p className="about__description">{description}</p>
        </div>
      </Col>
    </Row>
  );
};

export default AboutUsBanner;

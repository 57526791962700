// Packages
import { Col } from 'react-bootstrap';

// Components
import MainContent from '../UI/MainContent/MainContent';
import FormSearchProducts from '../Form/FormSearchProducts/FormSearchProducts';

// API

// CSS
import './SearchProducts.css';

const SearchProducts = (props) => {
    const { productTypes } = props;

    return (
        <MainContent>
            <Col className="p-0">
                <div className="search_products">
                    <FormSearchProducts productTypes={productTypes} />
                </div>
            </Col>
        </MainContent>
    );
};

export default SearchProducts;
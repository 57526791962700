// Packages
import { Row } from 'react-bootstrap';

// Components

// API

// CSS
import './MainContent.css';

const MainContent = (props) => {
    return (
        <Row className={`main_content ${props.className}`} >
            {props.children}
        </Row >
    );
};

export default MainContent;
// Packages
import { Fragment, useRef } from 'react';
import { Form, FloatingLabel, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";

// Components

// API
import { getSearchProducts } from '../../../api/request';

// CSS
import './FormSearchProducts.css';

const FormSearchProducts = (props) => {
    const { productTypes } = props;
    let navigate = useNavigate();
    let location = useLocation();

    const keywordRef = useRef();
    const typeRef = useRef();
    const priceRangeRef = useRef();

    const submitHandler = async (event) => {
        event.preventDefault();

        if (location.pathname === '/') {
            navigate('/products-services');
        }

        const enteredKeyword = keywordRef.current.value;
        const enteredType = typeRef.current.value;
        const enteredPriceRange = priceRangeRef.current.value;
        const data = await getSearchProducts(enteredKeyword, enteredType, enteredPriceRange);

        console.log(data);

        props.getSearchResult(data);
    };

    const resetHandler = async (event) => {
        event.preventDefault();
        props.getResetResult();
    };

    return (
        <Fragment>
            <span className="form_search__title">Search Products</span>
            <Form className="form_search" onSubmit={submitHandler}>
                <FloatingLabel controlId="floatingKeyword" label="Keyword">
                    <Form.Control type="text" placeholder="Keyword" ref={keywordRef} />
                </FloatingLabel>
                <FloatingLabel controlId="floatingSelect" label="Item type">
                    <Form.Select aria-label="Floating label select example" ref={typeRef}>
                        <option value="">Select</option>
                        {productTypes && productTypes.map(item => {
                            return <option key={item.id} value={item.id}>{item.type_name}</option>;
                        })}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId="floatingSelect" label="Price range">
                    <Form.Select aria-label="" ref={priceRangeRef}>
                        <option value="">Select</option>
                        <option value="0|50000">0 - 50,000</option>
                        <option value="50000|100000">50,000 - 100,000</option>
                        <option value="100000|200000">100,000 - 200,000</option>
                        <option value="200000|300000">200,000 - 300,000</option>
                        <option value="300000|400000">300,000 - 400,000</option>
                        <option value="400000|500000">400,000 - 500,000</option>
                        <option value="500000|1000000">500,000 - 1,000,000</option>
                    </Form.Select>
                </FloatingLabel>
                <Button className="button_form_search__submit" size="lg" type="submit">Apply filter</Button>
                <Button size="lg" onClick={(event) => resetHandler(event)}>Reset filter</Button>
            </Form >
        </Fragment >
    );
};

export default FormSearchProducts;
// Packages
import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from "../../components/Layout/Layout/Layout";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import ArticleBanner from "./ArticleBanner/ArticleBanner";
import Article from "./Article/Article";
import convertDate from "../../helpers/convertDate";

// API
import { getNews } from "../../api/request";

// CSS
import "./ViewArticlePage.css";

const ViewArticle = () => {
  const { id } = useParams();
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!news) dataNews(id);
  }, [news, id]);

  const dataNews = async (id) => {
    const data = await getNews(id);
    setNews(data);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`${news && news.title}`}</title>
      </Helmet>
      {isLoading && <LoadingScreen />}
      {!isLoading && news &&
        <Layout>
          <Container fluid>
            <PageHeader title="Press Release & Recent News" />
            <ArticleBanner
              title={news.title}
              author={news.author}
              date={convertDate(news.created_at)}
              image={news.news_image_url_path} />
            <Article id={news.id} categoryId={news.category.id} categoryName={news.category.name} description={news.description} />
          </Container>
        </Layout>
      }
    </Fragment>

  );
};

export default ViewArticle;

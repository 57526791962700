import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "445px",
};

const center = {
  lat: 14.61153,
  lng: 121.035221,
};

function CurrentLocation() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBmyWZHpAEeVLRujFvNBjA09GF6SrJpChs">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker position={center} />
        <></>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(CurrentLocation);

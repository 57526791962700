import API from './api';

const getFeaturedProducts = () => {
  return API.get('featured-products')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getAboutUs = () => {
  return API.get('about')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPartners = () => {
  return API.get('partners')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getCustomers = () => {
  return API.get('customers')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getAllNews = (page) => {
  return API.get(`news?page=${page}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getNews = (id) => {
  return API.get(`news/${id}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getRelatedNews = (id) => {
  return API.get(`news/${id}/related`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getProductTypes = () => {
  return API.get('product-types')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getProducts = (page) => {
  return API.get(`products?page=${page}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getProduct = (id) => {
  return API.get(`product/${id}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getServiceCenters = (id) => {
  return API.get(`service-centers`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getSearchProducts = (keyword, type, range) => {
  return API.post('product-filter', {
    keyword: keyword,
    product_type: type,
    price_range: range,
    itemPerPage: 12,
  });
};

const getSearchServiceCenter = (keyword) => {
  return API.post('/service-centers', {
    keyword: keyword,
  });
};

const newsletter = (email) => {
  return API.post('subscribe', {
    email,
  });
};

const inquiry = (
  name,
  email,
  company,
  address,
  contact_number,
  brand_model,
  serial_number,
  description,
  concern,
) => {
  return API.post('inquiry', {
    name: name,
    email: email,
    company: company,
    address: address && address,
    contact_number: contact_number,
    brand_model: brand_model && brand_model,
    // serial_number: serial_number && serial_number,
    description: description,
    concern: concern,
  });
};

export {
  getFeaturedProducts,
  getAboutUs,
  getPartners,
  getCustomers,
  getAllNews,
  getNews,
  getRelatedNews,
  getProducts,
  getProduct,
  getProductTypes,
  getServiceCenters,
  getSearchProducts,
  getSearchServiceCenter,
  newsletter,
  inquiry,
};

// Packages
import React, { Fragment, useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from '../../components/Layout/Layout/Layout';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import LocationsBanner from './LocationsBanner/LocationsBanner';
import LocationsList from './LocationsList/LocationsList';

// API
import { getServiceCenters } from "../../api/request";

// CSS
import './LocationsPage.css';

const LocationsPage = () => {
    const [serviceCenters, setServiceCenters] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!serviceCenters) dataServiceCenters();
    }, []);

    const dataServiceCenters = async () => {
        const data = await getServiceCenters();
        setServiceCenters(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <Fragment>
            <Helmet>
                <title>Locations</title>
            </Helmet>
            {isLoading && <LoadingScreen />}
            {!isLoading &&
                <Layout>
                    <Container fluid>
                        <PageHeader title="Service Center" />
                        <LocationsBanner />
                        <LocationsList serviceCenters={serviceCenters} />
                    </Container>
                </Layout>
            }
        </Fragment>
    );
};

export default LocationsPage;
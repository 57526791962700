// Packages
import { useState } from 'react';
import { Col, Button } from 'react-bootstrap';

// Components
import MainContent from '../../UI/MainContent/MainContent';
import Featured from '../../UI/Featured/Featured';

// API

// CSS
import './FeaturedGovernmentClients.css';

const FeaturedGovernmentClients = (props) => {
    const { governmentClients } = props;
    const [initialClients, setInitialClients] = useState(5);

    const showMoreHandler = () => {
        setInitialClients(governmentClients.length);
    };

    const viewLessHandler = () => {
        setInitialClients(5);
    };

    console.log();

    return (
        <MainContent>
            <Col className="p-0">
                <Featured title="Our government clients">
                    {governmentClients && governmentClients.slice(0, initialClients).map(partner => {
                        return <div className="featured__partner" key={partner.id} style={{ backgroundImage: `url('${partner.image_url_path}')` }}></div>;
                    })}
                    {(governmentClients && governmentClients.length >= initialClients) && (
                        <div className="featured__wrapper">
                            <Button className="featured__button" size="lg" onClick={() => {
                                if (governmentClients.length === initialClients) {
                                    viewLessHandler();
                                } else { showMoreHandler(); }
                            }}>{governmentClients && governmentClients.length === initialClients ? 'View Less' : 'Show more'}</Button>
                        </div>
                    )}
                </Featured>
            </Col>
        </MainContent>
    );
};

export default FeaturedGovernmentClients;
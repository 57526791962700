// Packages
import { useState, useEffect, useMemo } from 'react';
import { Col, Pagination, Alert } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';
import FormSearchProducts from '../../../components/Form/FormSearchProducts/FormSearchProducts';
import ProductCard from '../ProductCard/ProductCard';

// API
import { getProducts } from "../../../api/request";

// CSS
import './ProductsList.css';

const ProductsList = (props) => {
    const [products, setProducts] = useState(props.products);
    const [active, setActive] = useState(1);
    const [paginationTotal, setPaginationTotal] = useState(props.products && props.products.meta.last_page);
    const [searchResult, setSearchResult] = useState(true);

    const dataProducts = async (active) => {
        const data = await getProducts(active);
        setProducts(data);
        setPaginationTotal(data.meta.last_page);
        setActive(active !== undefined ? active : 1);
    };

    // const firstPage = async () => {
    //     setActive(1);
    //     const data = await getProducts(1);
    //     setProducts(data);
    // };

    // const lastPage = async () => {
    //     setActive(products.meta.last_page);
    //     const data = await getProducts(products.meta.last_page);
    //     setProducts(data);
    // };

    const prevPage = async () => {
        setActive(active !== 1 ? active - 1 : 1);
        const data = await getProducts(active !== 1 ? active - 1 : 1);
        setProducts(data);
    };

    const nextPage = async () => {
        setActive(active !== products.meta.last_page ? active + 1 : products.meta.last_page);
        const data = await getProducts(active !== products.meta.last_page ? active + 1 : products.meta.last_page);
        setProducts(data);
    };

    const renderPagination = () => {
        let pagination = [];
        for (let i = 1; i <= paginationTotal; i++) {
            pagination.push(
                <Pagination.Item key={i} active={i === active} onClick={() => { dataProducts(i); }} >
                    {i}
                </Pagination.Item >
            );
        }
        return pagination;
    };

    const getSearchResult = (data) => {
        try {
            if (data.data.error) throw new Error();
            setActive(1);
            setPaginationTotal(data.data.meta.last_page);
            setProducts(data.data);
            setSearchResult(true);
        } catch (e) {
            setActive(1);
            setPaginationTotal(0);
            setProducts(data.data);
        }
    };

    const getResetResult = () => {
        setActive(1);
        setProducts(props.products && props.products);
        setPaginationTotal(props.products && props.products.meta.last_page);
        setSearchResult(true);
    };


    useMemo(() => dataProducts(), []);

    return (
        <MainContent>
            <Col className="p-0">
                <div className="products">
                    <div className="products__wrapper">
                        <FormSearchProducts productTypes={props.productTypes} getSearchResult={getSearchResult} getResetResult={getResetResult} />
                    </div>
                    <div className="products_list">
                        {products.data && products.data.map((item) => {
                            return <ProductCard key={item.id} id={item.id} name={item.product_name} price={item.product_price} image={item.image_url_path} />;
                        })}
                        {products.error && <div className="products__placeholder">No products found.</div>}
                    </div>
                    {products.data &&
                        <Pagination>
                            {/* <Pagination.First onClick={() => firstPage()} /> */}
                            <Pagination.Prev onClick={() => prevPage()} />
                            {renderPagination()}
                            <Pagination.Next onClick={() => nextPage()} />
                            {/* <Pagination.Last onClick={() => lastPage()} /> */}
                        </Pagination>
                    }
                </div>
            </Col>
        </MainContent >
    );
};

export default ProductsList;
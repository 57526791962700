// Packages
import { Fragment } from 'react';

// Components

// API

// CSS

const Content = (props) => {
    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default Content;
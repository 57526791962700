// Packages
import { Link } from 'react-router-dom';

// Components

// API

// CSS
import './ProductTypeCard.css';

const ProductTypeCard = (props) => {
    return (
        <div className="product_type_card">
            <Link className="product_type_card__link" to={props.link}></Link>
            <div className="product_type_card__image" style={{ backgroundImage: `url(${props.image})` }}>
                {props.children}
            </div>
            <div className="product_type_card__body">
                <span className="product_type_card__title">{props.title}</span>
            </div>
        </div>
    );
};

export default ProductTypeCard;
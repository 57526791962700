// Packages
import React, { Fragment, useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from '../../components/Layout/Layout/Layout';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import ProductsList from './ProductsList/ProductsList';
import FeaturedPartners from '../../components/Featured/FeaturedPartners/FeaturedPartners';
import FeaturedCorporateCustomers from '../../components/Featured/FeaturedCorporateCustomers/FeaturedCorporateCustomers';
import FeaturedGovernmentClients from '../../components/Featured/FeaturedGovernmentClients/FeaturedGovernmentClients';

// API
import { getProducts, getProductTypes, getPartners, getCustomers } from "../../api/request";

// CSS
import './ProductsPage.css';

const ProductsPage = (props) => {
    const [products, setProducts] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [partners, setPartners] = useState([]);
    const [governmentClients, setGovernmentClients] = useState([]);
    const [corporateClients, setCorporateClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dataProducts();
        dataProductTypes();
        dataPartners();
        dataClients();
    }, []);

    const dataProducts = async () => {
        const data = await getProducts();
        setProducts(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    const dataProductTypes = async () => {
        const data = await getProductTypes();
        setProductTypes(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    const dataPartners = async () => {
        const data = await getPartners();
        setPartners(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    // Get customers
    const dataClients = async () => {
        const data = await getCustomers();
        let governmentClientsList = data && data.filter((customer) => {
            return customer.type === "GOVERNMENT";
        });
        let corporateClientsList = data && data.filter((customer) => {
            return customer.type === "CORPORATE";
        });
        setGovernmentClients(governmentClientsList);
        setCorporateClients(corporateClientsList);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <Fragment>
            <Helmet>
                <title>Products & Services</title>
            </Helmet>
            {isLoading && <LoadingScreen />}
            {!isLoading && <Layout>
                <Container fluid>
                    <PageHeader title="Products & Services" />
                    <ProductsList productTypes={productTypes} products={products} />
                    {partners.length > 0 && (
                        <FeaturedPartners partners={partners} />
                    )}
                    {corporateClients.length > 0 && (
                        <FeaturedCorporateCustomers corporateClients={corporateClients} />
                    )}
                    {governmentClients.length > 0 && (
                        <FeaturedGovernmentClients governmentClients={governmentClients} />
                    )}
                </Container>
            </Layout>}
        </Fragment>

    );
};

export default ProductsPage;
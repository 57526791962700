// Packages
import { Row, Col } from 'react-bootstrap';

// Components

// API

// CSS
import './PageHeader.css';

const PageHeader = (props) => {
    return (
        <Row>
            <Col lg={12} className="p-0">
                <div className="page__header">
                    <span className="page__title">{props.title}</span>
                </div>
            </Col>
        </Row>
    );
};

export default PageHeader;
// Packages
import { useState } from 'react';
import { Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import Validator from 'validator';

// Components
import InquiryOverlay from '../../../components/InquiryOverlay/InquiryOverlay';

// API
import { inquiry } from '../../../api/request';

// CSS
import './FormTechnicalConcern.scss';

const FormTechnicalConcern = (props) => {
  const [active, setActive] = useState(false);

  // Name State
  const [enteredName, setEnteredName] = useState('');
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);
  // Email State
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  // Mobile State
  const [enteredMobile, setEnteredMobile] = useState('');
  const [enteredMobileTouched, setEnteredMobileTouched] = useState(false);
  // Office / Agency State
  const [enteredOffice, setEnteredOffice] = useState('');
  const [enteredOfficeTouched, setEnteredOfficeTouched] = useState(false);
  // Office / Agency Address State
  const [enteredOfficeAddress, setEnteredOfficeAddress] = useState('');
  const [enteredOfficeAddressTouched, setEnteredOfficeAddressTouched] = useState(false);
  // Brand And Model State
  //   const [enteredBrandAndModel, setEnteredBrandAndModel] = useState('');
  //   const [enteredBrandAndModelTouched, setEnteredBrandAndModelTouched] = useState(false);
  // Serial Number State
  //   const [enteredSerialNumber, setEnteredSerialNumber] = useState('');
  //   const [enteredSerialNumberTouched, setEnteredSerialNumberTouched] = useState(false);
  // Message State
  const [enteredMessage, setEnteredMessage] = useState('');
  const [enteredMessageTouched, setEnteredMessageTouched] = useState(false);

  // Name Validation
  const enteredNameIsValid = enteredName.trim() !== '';
  const inputNameIsValid = !enteredNameIsValid && enteredNameTouched;
  const inputNameChangeHandler = (event) =>
    setEnteredName(event.target.value.replace(/[^a-zA-Z\s]*$/, ''));
  const inputNameBlurHandler = () => setEnteredNameTouched(true);
  // Office Validation
  const enteredOfficeIsValid = enteredOffice.trim() !== '';
  const inputOfficeIsValid = !enteredOfficeIsValid && enteredOfficeTouched;
  const inputOfficeChangeHandler = (event) =>
    setEnteredOffice(event.target.value.replace(/[^a-zA-Z0-9\s]*$/, ''));
  const inputOfficeBlurHandler = () => setEnteredOfficeTouched(true);
  // Office Address Validation
  const enteredOfficeAddressIsValid = enteredOfficeAddress.trim() !== '';
  const inputOfficeAddressIsValid = !enteredOfficeAddressIsValid && enteredOfficeAddressTouched;
  const inputOfficeAddressChangeHandler = (event) => setEnteredOfficeAddress(event.target.value);
  const inputOfficeAddressBlurHandler = () => setEnteredOfficeAddressTouched(true);
  // Email Validation
  const enteredEmailIsValid = enteredEmail.trim() !== '' && Validator.isEmail(enteredEmail);
  const inputEmailIsValid = !enteredEmailIsValid && enteredEmailTouched;
  const inputEmailChangeHandler = (event) => setEnteredEmail(event.target.value);
  const inputEmailBlurHandler = () => setEnteredEmailTouched(true);
  // Mobile Validation
  const enteredMobileIsValid = enteredMobile.trim() !== '' && enteredMobile.length === 11;
  const inputMobileIsValid = !enteredMobileIsValid && enteredMobileTouched;
  const inputMobileChangeHandler = (event) =>
    setEnteredMobile(event.target.value.replace(/[^0-9]*$/, '').slice(0, 11));
  const inputMobileBlurHandler = () => setEnteredMobileTouched(true);
  // Brand and Model
  //   const enteredBrandAndModelIsValid = enteredBrandAndModel.trim() !== '';
  //   const inputBrandAndModelIsValid = !enteredBrandAndModelIsValid && enteredBrandAndModelTouched;
  //   const inputBrandAndModelChangeHandler = (event) =>
  //     setEnteredBrandAndModel(event.target.value.replace(/[^a-zA-Z\s]*$/, ''));
  //   const inputBrandAndModelBlurHandler = () => setEnteredBrandAndModelTouched(true);
  // Serial Number
  //   const enteredSerialNumberIsValid = enteredSerialNumber.trim() !== '';
  //   const inputSerialNumberIsValid = !enteredSerialNumberIsValid && enteredSerialNumberTouched;
  //   const inputSerialNumberChangeHandler = (event) =>
  //     setEnteredSerialNumber(event.target.value.replace(/[^a-zA-Z0-9\s]*$/, ''));
  //   const inputSerialNumberBlurHandler = () => setEnteredSerialNumberTouched(true);
  // Message Validation
  const enteredMessageIsValid = enteredMessage.trim() !== '';
  const inputMessageIsValid = !enteredMessageIsValid && enteredMessageTouched;
  const inputMessageChangeHandler = (event) => setEnteredMessage(event.target.value);
  const inputMessageBlurHandler = () => setEnteredMessageTouched(true);

  let formIsValid = false;

  if (
    enteredNameIsValid &&
    enteredOfficeIsValid &&
    enteredOfficeAddressIsValid &&
    enteredEmailIsValid &&
    enteredMobileIsValid &&
    // enteredBrandAndModelIsValid &&
    // enteredSerialNumberIsValid &&
    enteredMessageIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    if (formIsValid) {
      const data = await inquiry(
        enteredName,
        enteredEmail,
        enteredMobile,
        enteredOffice,
        enteredOfficeAddress,
        props && props.name,
        // enteredBrandAndModel,
        // enteredSerialNumber,
        enteredMessage,
        'technical_concern',
      );

      if (data.data.success === true) {
        setActive(true);
      }

      setEnteredName('');
      setEnteredNameTouched(false);
      setEnteredEmail('');
      setEnteredEmailTouched(false);
      setEnteredMobile('');
      setEnteredMobileTouched(false);
      setEnteredOffice('');
      setEnteredOfficeTouched(false);
      setEnteredOfficeAddress('');
      setEnteredOfficeAddressTouched(false);
      //   setEnteredBrandAndModel('');
      //   setEnteredBrandAndModelTouched(false);
      //   setEnteredSerialNumber('');
      //   setEnteredSerialNumberTouched(false);
      setEnteredMessage('');
      setEnteredMessageTouched(false);
    }
  };

  const closeOverlayHandler = () => {
    setActive(false);
  };

  if (active) setTimeout(() => setActive(false), 5000);

  return (
    <div className='form_technical_concern'>
      <Form onSubmit={submitHandler}>
        <div className='form__header'>
          <span className='title'>Inquire for {props && props.name}</span>
          <span className='description'>We will get in touch with you shortly.</span>
        </div>
        <FloatingLabel
          controlId='floatingInput'
          label='Name *'
        >
          <Form.Control
            type='text'
            value={enteredName}
            onInput={(event) => inputNameChangeHandler(event)}
            onBlur={() => inputNameBlurHandler()}
          />
          {inputNameIsValid && (
            <Form.Text className='text-danger'>Please provide your name.</Form.Text>
          )}
        </FloatingLabel>
        <Row>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Email *'
            >
              <Form.Control
                type='email'
                value={enteredEmail}
                onInput={(event) => inputEmailChangeHandler(event)}
                onBlur={() => inputEmailBlurHandler()}
              />
              {inputEmailIsValid && (
                <Form.Text className='text-danger'>Please provide a valid email address.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Mobile *'
            >
              <Form.Control
                type='text'
                value={enteredMobile}
                onInput={(event) => inputMobileChangeHandler(event)}
                onBlur={() => inputMobileBlurHandler()}
              />
              {inputMobileIsValid && (
                <Form.Text className='text-danger'>
                  Please provide an 11-digit mobile number.
                </Form.Text>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Office / Agency *'
            >
              <Form.Control
                type='text'
                value={enteredOffice}
                onInput={(event) => inputOfficeChangeHandler(event)}
                onBlur={() => inputOfficeBlurHandler()}
              />
              {inputOfficeIsValid && (
                <Form.Text className='text-danger'>Please provide your office / agency.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Office / Agency Address *'
            >
              <Form.Control
                type='text'
                value={enteredOfficeAddress}
                onInput={(event) => inputOfficeAddressChangeHandler(event)}
                onBlur={() => inputOfficeAddressBlurHandler()}
              />
              {inputOfficeAddressIsValid && (
                <Form.Text className='text-danger'>
                  Please provide your office / agency address.
                </Form.Text>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        {/* <Row>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Brand & Model *'
            >
              <Form.Control
                type='text'
                value={enteredBrandAndModel}
                onInput={(event) => inputBrandAndModelChangeHandler(event)}
                onBlur={() => inputBrandAndModelBlurHandler()}
              />
              {inputBrandAndModelIsValid && (
                <Form.Text className='text-danger'>Please provide a brand and model.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Serial Number *'
            >
              <Form.Control
                type='text'
                value={enteredSerialNumber}
                onInput={(event) => inputSerialNumberChangeHandler(event)}
                onBlur={() => inputSerialNumberBlurHandler()}
              />
              {inputSerialNumberIsValid && (
                <Form.Text className='text-danger'>Please provide a serial number.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
        </Row> */}
        <FloatingLabel
          controlId='floatingInput'
          label='Detailed Description *'
          className='mb-5'
        >
          <Form.Control
            as='textarea'
            style={{ height: '180px' }}
            value={enteredMessage}
            onInput={(event) => inputMessageChangeHandler(event)}
            onBlur={() => inputMessageBlurHandler()}
          />
          {inputMessageIsValid && (
            <Form.Text className='text-danger'>Please provide your message.</Form.Text>
          )}
        </FloatingLabel>
        <Button
          className='btn'
          size='lg'
          type='submit'
          disabled={!formIsValid}
        >
          Send message
        </Button>
        {active && <InquiryOverlay closeOverlayHandler={closeOverlayHandler} />}
      </Form>
    </div>
  );
};

export default FormTechnicalConcern;

// Packages
import { Fragment } from 'react';
import { Col } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';
import FormTechnicalConcern from "../FormTechnicalConcern/FormTechnicalConcern";

// API

// CSS
import './ViewProduct.css';

const ViewProduct = (props) => {
    return (
        <Fragment>
            <MainContent>
                <Col className="p-0">
                    <div className="product">
                        <div className="product__header">
                            <div className="product__image" style={{ backgroundImage: `url(${props.product && props.product.image_url_path})` }}></div>
                            <div className="product__content">
                                <span className="product__name">{props.product && props.product.product_name}</span>
                                <span className="product__price">PHP {props.product && props.product.product_price}</span>
                                <div className="product__details">
                                    <span className="product__category">
                                        {props.product && props.product.product_type.type_name}
                                    </span>
                                </div>
                                <div className="product__description">
                                    <h6 className="product__title">Description</h6>
                                    <p>{props.product && props.product.product_description}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            </MainContent>
            <MainContent>
                <Col className="p-0">
                    <FormTechnicalConcern name={props.product && props.product.product_name} />
                </Col>
            </MainContent>
        </Fragment>
    );
};

export default ViewProduct;
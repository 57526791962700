// Packages
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Carousel } from 'react-bootstrap';

// Components

// API

// CSS
import './FeaturedProducts.css';

const FeaturedProducts = (props) => {
    const { featuredProducts } = props;

    return (
        <Fragment>
            <Carousel>
                {featuredProducts && featuredProducts.map((product) => {
                    return (
                        <Carousel.Item key={product.id}>
                            <Row className="featured_product">
                                <Col lg={6} className="p-0">
                                    <div className="featured_product__wrapper">
                                        <div className="featured_product__image" style={{ backgroundImage: `url(${product.image_url_path})` }}></div>
                                    </div>
                                </Col>
                                <Col lg={6} className="p-0">
                                    <div className="featured_product__body">
                                        <h1 className="featured_product__slogan">{product.header_title}</h1>
                                        <h3 className="featured_product__name">{product.product_name}</h3>
                                        <p className="featured_product__description">{product.product_description}</p>
                                        <Link className="featured_product__button" to={`/products-services/${product.id}`}>
                                            LEARN MORE
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </Fragment>
    );
};

export default FeaturedProducts;
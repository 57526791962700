// Packages
import React, { Fragment, useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Layout from '../../components/Layout/Layout/Layout';
import FeaturedProducts from '../../components/Featured/FeaturedProducts/FeaturedProducts';
import SearchProducts from '../../components/SearchProducts/SearchProducts';
import ProductTypesList from './ProductTypesList/ProductTypesList';
import FeaturedPartners from '../../components/Featured/FeaturedPartners/FeaturedPartners';
import FeaturedCorporateCustomers from '../../components/Featured/FeaturedCorporateCustomers/FeaturedCorporateCustomers';
import FeaturedGovernmentClients from '../../components/Featured/FeaturedGovernmentClients/FeaturedGovernmentClients';

// API
import { getFeaturedProducts, getProductTypes, getPartners, getCustomers } from "../../api/request";

// CSS
import './HomePage.css';


const HomePage = () => {
    // State
    const [featuredProducts, setFeaturedProducts] = useState("");
    const [productTypes, setProductTypes] = useState("");
    const [partners, setPartners] = useState([]);
    const [governmentClients, setGovernmentClients] = useState([]);
    const [corporateClients, setCorporateClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dataFeaturedProducts();
        dataProductTypes();
        dataPartners();
        dataClients();
    }, []);

    // Get featured products
    const dataFeaturedProducts = async () => {
        const data = await getFeaturedProducts();
        setFeaturedProducts(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    // Get product types
    const dataProductTypes = async () => {
        const data = await getProductTypes();
        setProductTypes(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    // Get partners
    const dataPartners = async () => {
        const data = await getPartners();
        setPartners(data);
        setTimeout(() => setIsLoading(false), 500);
    };

    // Get customers
    const dataClients = async () => {
        const data = await getCustomers();
        let governmentClientsList = data && data.filter((customer) => {
            return customer.type === "GOVERNMENT";
        });
        let corporateClientsList = data && data.filter((customer) => {
            return customer.type === "CORPORATE";
        });
        setGovernmentClients(governmentClientsList);
        setCorporateClients(corporateClientsList);
        setTimeout(() => setIsLoading(false), 500);
    };

    return (
        <Fragment>
            <Helmet>
                <title>APTI</title>
            </Helmet>
            {isLoading && <LoadingScreen />}
            {!isLoading && (
                <Layout>
                    <Container fluid className="p-0">
                        <FeaturedProducts featuredProducts={featuredProducts} />
                    </Container>
                    <Container fluid>
                        <SearchProducts productTypes={productTypes} />
                        <ProductTypesList productTypes={productTypes} />
                        {partners.length > 0 && (
                            <FeaturedPartners partners={partners} />
                        )}
                        {corporateClients.length > 0 && (
                            <FeaturedCorporateCustomers corporateClients={corporateClients} />
                        )}
                        {governmentClients.length > 0 && (
                            <FeaturedGovernmentClients governmentClients={governmentClients} />
                        )}
                    </Container>
                </Layout>
            )}
        </Fragment>
    );
};

export default HomePage;
// Packages
import { Row, Col } from "react-bootstrap";

// Components
import MainContent from "../../../components/UI/MainContent/MainContent";
import Map from "../Map/Map";

// API

// CSS

import './ContactInformation.css';

const ContactInformation = (props) => {
    return (
        <MainContent className="">
            <Col lg="12" className="p-0">
                <span className="contact__header">OUR LOCATION</span>
            </Col>
            <Col lg="3" className="p-0">
                <div className="contact_information">
                    <span className="contact_information__title">CONTACT INFORMATION</span>
                    <ul className="contact_information__details">
                        <li className="contact_information__detail">
                            <svg viewBox="0 0 27 24">
                                <path id="icons8_map" d="M14.25,8.553,6.463,14.215a1.123,1.123,0,0,0-.463.91v15.75a1.125,1.125,0,0,0,1.786.91l6.463-4.7Zm8.25,4.363-6-4.363V27.084l6,4.363Zm9.886-4.793a1.125,1.125,0,0,0-1.172.093l-6.463,4.7V31.447l7.786-5.662a1.123,1.123,0,0,0,.463-.91V9.125A1.125,1.125,0,0,0,32.386,8.122Z" transform="translate(-6 -8)" fill="#fff" />
                            </svg>
                            <span>Unit 405 PM Building No. 26 N.Domingo St. Corner Gilmore Avenue Quezon City, Metro Manila 1112</span>
                        </li>
                        <li className="contact_information__detail">
                            <svg viewBox="0 0 26.665 24">
                                <path id="icons8_telephone" d="M17.331,6c-5.262,0-9.7,1.134-11.875,3.032a4.119,4.119,0,0,0-1.394,3.847l.216.76a3.192,3.192,0,0,0,3.446,2.344l1.693-.212a3.118,3.118,0,0,0,2.767-2.294l.466-2.068a.555.555,0,0,1,.256-.345,18.334,18.334,0,0,1,4.425-.4,18.316,18.316,0,0,1,4.424.4.609.609,0,0,1,.273.4l.434,1.959a3.133,3.133,0,0,0,2.764,2.346l1.73.217c.109.011.217.016.324.016a3.164,3.164,0,0,0,3.1-2.336l.207-.72.016-.065A4.122,4.122,0,0,0,29.2,9.028C27.032,7.13,22.593,6,17.331,6Zm-2.349,7.321A1,1,0,0,0,14,14.331v1.716L11.62,17.236A10.112,10.112,0,0,0,6,26.33,3.67,3.67,0,0,0,9.665,30H25a3.67,3.67,0,0,0,3.666-3.666,10.112,10.112,0,0,0-5.62-9.094l-2.379-1.189V14.331a1,1,0,1,0-2,0v1H16v-1a1,1,0,0,0-1.015-1.014Zm-1.318,7.347a1,1,0,1,1-1,1A1,1,0,0,1,13.665,20.664Zm3.666,0a1,1,0,1,1-1,1A1,1,0,0,1,17.331,20.664Zm3.666,0a1,1,0,1,1-1,1A1,1,0,0,1,21,20.664ZM13.665,24a1,1,0,1,1-1,1A1,1,0,0,1,13.665,24Zm3.666,0a1,1,0,1,1-1,1A1,1,0,0,1,17.331,24ZM21,24a1,1,0,1,1-1,1A1,1,0,0,1,21,24Z" transform="translate(-3.998 -5.996)" fill="#fff" />
                            </svg>
                            <span>Tel No. 8721-5833 to 35</span>
                        </li>
                        <li className="contact_information__detail">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.351" height="24" viewBox="0 0 23.351 24">
                                <path id="icons8_fax_1" d="M17.351,5a.973.973,0,0,0-.973.973v2.3a2.913,2.913,0,0,0-2.595,2.886V26.081A2.922,2.922,0,0,0,16.7,29h9.73a2.922,2.922,0,0,0,2.919-2.919V11.162a2.913,2.913,0,0,0-2.595-2.886v-2.3A.973.973,0,0,0,25.784,5ZM8.9,5.635a.973.973,0,0,0-.958.987v1.8A2.915,2.915,0,0,0,6,11.162V26.081A2.922,2.922,0,0,0,8.919,29h.649a2.922,2.922,0,0,0,2.919-2.919V11.162A2.913,2.913,0,0,0,9.892,8.276V6.622A.973.973,0,0,0,8.9,5.635Zm9.421,1.311h6.486v5.838H18.324ZM18,16.676a.973.973,0,1,1-.973.973A.973.973,0,0,1,18,16.676Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,21.568,16.676Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,25.135,16.676ZM18,19.919a.973.973,0,1,1-.973.973A.973.973,0,0,1,18,19.919Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,21.568,19.919Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,25.135,19.919ZM18,23.162a.973.973,0,1,1-.973.973A.973.973,0,0,1,18,23.162Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,21.568,23.162Zm3.568,0a.973.973,0,1,1-.973.973A.973.973,0,0,1,25.135,23.162Z" transform="translate(-6 -5)" fill="#fff" />
                            </svg>
                            <span>Fax No. 8721-0052</span>
                        </li>
                        <li className="contact_information__detail">
                            <svg viewBox="0 0 24 23.423">
                                <path id="icons8_chat_1" d="M7.9,6A3.9,3.9,0,0,0,4,9.9V23.121a1.5,1.5,0,0,0,2.382,1.214L11.792,20.4H19.3a3.9,3.9,0,0,0,3.9-3.9V9.9A3.9,3.9,0,0,0,19.3,6Zm16.5,4.812V16.5a5.1,5.1,0,0,1-5.1,5.1H12.184L9.514,23.538A3.893,3.893,0,0,0,12.7,25.2h7.506l5.412,3.936a1.491,1.491,0,0,0,.882.287,1.455,1.455,0,0,0,.679-.168A1.478,1.478,0,0,0,28,27.923V14.7A3.91,3.91,0,0,0,24.4,10.812Z" transform="translate(-4 -6)" />
                            </svg>
                            <span>marketing@apti.com.ph <br /> accelprime@apti.com.ph</span>
                        </li>
                        <li className="contact_information__detail">
                            <svg viewBox="0 0 25 23.81">
                                <path id="icons8_technical_support" d="M14.9,4C7.451,4,3,10.119,3,20.369v2.976a3.273,3.273,0,0,0,3.274,3.274H11.83a6.249,6.249,0,0,0,7.338,0h5.558A3.273,3.273,0,0,0,28,23.345V20.369c0-11.3-5.385-14.62-8.547-14.866A6.7,6.7,0,0,0,14.9,4Zm3.513,7.8a4.752,4.752,0,0,1,3.628,4.971c0,.026,0,2.4,0,2.4a2.682,2.682,0,0,1-2.679,2.679h-1.1a2.085,2.085,0,1,0,0,1.786h1.1c.119,0,.236-.009.352-.017a5.445,5.445,0,0,1-4.221,2.4c-3.384,0-6.258-4.068-6.548-9.262v-1.75a.893.893,0,0,1,.893-.893C10.081,14.119,15.428,14.091,18.418,11.8Zm5.452,3.51a2.379,2.379,0,0,1,2.344,2.377v1.19a2.381,2.381,0,0,1-2.381,2.381h-.519a4.431,4.431,0,0,0,.519-2.083S23.86,16.278,23.871,15.313ZM12.524,16.5a1.19,1.19,0,1,0,1.19,1.19A1.19,1.19,0,0,0,12.524,16.5Zm5.952,0a1.19,1.19,0,1,0,1.19,1.19A1.19,1.19,0,0,0,18.476,16.5Z" transform="translate(-3 -4)" />
                            </svg>
                            <span>technical@apti.com.ph <br /> apti.technical@gmail.com</span>
                        </li>
                    </ul>
                </div>
            </Col>
            <Col lg="9" className="p-0">
                <Map />
            </Col>
        </MainContent>
    );
};

export default ContactInformation;
// Packages
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

// Components
import MainContent from "../../../components/UI/MainContent/MainContent";
import RelatedArticles from "../RelatedArticles/RelatedArticles";

// API
import { getRelatedNews } from "../../../api/request";

// CSS
import "./Article.css";

const Article = (props) => {
  const [relatedArticles, setRelatedArticles] = useState("");

  useEffect(() => {
    if (!relatedArticles) dataNews();
  }, []);

  const dataNews = async () => {
    const data = await getRelatedNews(props && props.id);
    setRelatedArticles(data);
  };

  return (
    <MainContent>
      <Col lg="8" className="p-0">
        <div className="article">
          <div className="article__information">
            <span className="article__category">
              {props && props.categoryName}
            </span>
          </div>
          <div className="article__body">{props && props.description}</div>
        </div>
      </Col>
      <Col lg="4" className="p-0">
        {relatedArticles && relatedArticles.length !== 0 && (
          <RelatedArticles relatedArticles={relatedArticles} />
        )}
      </Col>
    </MainContent>
  );
};

export default Article;

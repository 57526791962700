// Packages
import { useState } from 'react';
import { Col, Button } from 'react-bootstrap';

// Components
import MainContent from '../../UI/MainContent/MainContent';
import Featured from '../../UI/Featured/Featured';

// API

// CSS
import './FeaturedCorporateCustomers.css';

const FeaturedCorporateCustomers = (props) => {
    const [initialClients, setInitialClients] = useState(5);
    const { corporateClients } = props;

    const showMoreHandler = () => {
        setInitialClients(corporateClients.length);
    };

    const viewLessHandler = () => {
        setInitialClients(5);
    };

    return (
        <MainContent>
            <Col className="p-0">
                <Featured title="Our corporate customers">
                    {corporateClients && corporateClients.slice(0, initialClients).map(partner => {
                        return <div className="featured__partner" key={partner.id} style={{ backgroundImage: `url('${partner.image_url_path}')` }}></div>;
                    })}
                    {(corporateClients && corporateClients.length >= initialClients) && (
                        <div className="featured__wrapper">
                            <Button className="featured__button" size="lg" onClick={() => {
                                if (corporateClients.length === initialClients) {
                                    viewLessHandler();
                                } else { showMoreHandler(); }
                            }}>{corporateClients && corporateClients.length === initialClients ? 'View Less' : 'Show more'}</Button>
                        </div>
                    )}
                </Featured>
            </Col>
        </MainContent>
    );
};

export default FeaturedCorporateCustomers;
// Packages
import { Col } from 'react-bootstrap';

// Components
import CurrentLocation from './CurrentLocation';

// API
import { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

// CSS
import './Map.css';

const Map = (props) => {
    return (
        <div className="map">
            <div className="map__object">
                {props.children}
                <CurrentLocation />
            </div>
        </div>
    );
};

export default Map;
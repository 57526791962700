// Packages
import { Fragment, useState, useRef } from 'react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';

// Components
import SubscriptionOverlay from "../../SubscriptionOverlay/SubscriptionOverlay";

// API
import { newsletter } from '../../../api/request';

// CSS
import './FormNewsLetter.css';

const FormNewsLetter = () => {
    const [disabled, setDisabled] = useState(false);
    const [active, setActive] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const emailRef = useRef();

    const submitHandler = async (event) => {
        event.preventDefault();

        const enteredEmail = emailRef.current.value;
        const data = await newsletter(enteredEmail);
        console.log(data);

        if (data.data.success === "Thank you for subscribing.") {
            setActive(true);
            setDisabled(true);
        } else {
            setSubscribed(true);
            setDisabled(true);
        }
    };

    const closeOverlayHandler = () => {
        setActive(false);
    };

    if (disabled) setTimeout(() => setDisabled(false), 20000);
    if (active) setTimeout(() => setActive(false), 5000);
    if (subscribed) setTimeout(() => setSubscribed(false), 20000);

    return (
        <Fragment>
            <Form className="formNewsletter footer__column" onSubmit={submitHandler}>
                <span className="column__title">Stay updated</span>
                <div className="column__description">Subscribe to our newsletter.</div>
                <InputGroup className="mb-3">
                    <FormControl
                        ref={emailRef}
                        type="email"
                        placeholder="Email address"
                        required
                    />
                    <Button className="button__newsletter" type="submit" disabled={disabled}>
                        <i className="bi bi-arrow-right"></i>
                    </Button>
                </InputGroup>
                {subscribed && (
                    <span className="error">You're already subscribed.</span>
                )}
            </Form>
            {active && <SubscriptionOverlay closeOverlayHandler={closeOverlayHandler} />}
        </Fragment>
    );
};

export default FormNewsLetter;
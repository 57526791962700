// Packages
import { Col } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';
import FormSalesMarketing from "../FormSalesMarketing/FormSalesMarketing";

// API

// CSS
import './ContactUsBanner.css';

const Contact = (props) => {
    const { productTypes } = props;

    return (
        <MainContent>
            <Col className="p-0">
                <div className="contact">
                    <FormSalesMarketing />
                </div>
            </Col>
        </MainContent>
    );
};

export default Contact;
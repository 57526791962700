// Packages
import { Link } from 'react-router-dom';

// Components

// API

// CSS
import './ProductCard.css';

const ProductCard = (props) => {
    return (
        <div className="product_card">
            <Link className="product_card__link" to={'/products-services/' + props.id}></Link>
            <div className="product_card__image" style={{ backgroundImage: `url(${props.image})` }}>
            </div>
            <div className="product_card__body">
                <span className="product_card__name">{props.name}</span>
                <span className="product_card__price">Php {props.price}</span>
            </div>
        </div >
    );
};

export default ProductCard;
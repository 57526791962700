// Packages
import { Col } from 'react-bootstrap';

// Components
import MainContent from '../../../components/UI/MainContent/MainContent';
import ProductTypeCard from '../ProductTypeCard/ProductTypeCard';

// API

// CSS
import './ProductTypesList.css';

const ProductTypesList = (props) => {
    const { productTypes } = props;

    return (
        <MainContent>
            <Col className="p-0">
                <div className="product_types_list">
                    <ProductTypeCard title="View all" link="/products-services">
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h9v9H0V0zm2 2v5h5V2H2zm-2 9h9v9H0v-9zm2 2v5h5v-5H2zm9-13h9v9h-9V0zm2 2v5h5V2h-5zm-2 9h9v9h-9v-9zm2 2v5h5v-5h-5z" /></svg>
                    </ProductTypeCard>
                    {productTypes && productTypes.map((item) => {
                        return (
                            <ProductTypeCard key={item.id} title={item.type_name} image={item.image_url_path !== null ? item.image_url_path : '/images/placeholder-image.png'} link="/products-services" />
                        );
                    })}
                </div>
            </Col>
        </MainContent>
    );
};

export default ProductTypesList;
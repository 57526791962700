// Packages
import { useState } from 'react';
import { Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import Validator from 'validator';

// Components
import { inquiry } from '../../../api/request';
import InquiryOverlay from '../../../components/InquiryOverlay/InquiryOverlay';

// API

// SCSS
import './FormSalesMarketing.scss';

const FormSalesMarketing = (props) => {
  const [active, setActive] = useState(false);

  // Name State
  const [enteredName, setEnteredName] = useState('');
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);
  // Email State
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  // Mobile State
  const [enteredMobile, setEnteredMobile] = useState('');
  const [enteredMobileTouched, setEnteredMobileTouched] = useState(false);
  // Office / Agency State
  const [enteredOffice, setEnteredOffice] = useState('');
  const [enteredOfficeTouched, setEnteredOfficeTouched] = useState(false);
  // Message State
  const [enteredMessage, setEnteredMessage] = useState('');
  const [enteredMessageTouched, setEnteredMessageTouched] = useState(false);

  // Name Validation
  const enteredNameIsValid = enteredName.trim() !== '';
  const inputNameIsValid = !enteredNameIsValid && enteredNameTouched;
  const inputNameChangeHandler = (event) =>
    setEnteredName(event.target.value.replace(/[^a-zA-Z\s]*$/, ''));
  const inputNameBlurHandler = () => setEnteredNameTouched(true);
  // Office Validation
  const enteredOfficeIsValid = enteredOffice.trim() !== '';
  const inputOfficeIsValid = !enteredOfficeIsValid && enteredOfficeTouched;
  const inputOfficeChangeHandler = (event) =>
    setEnteredOffice(event.target.value.replace(/[^a-zA-Z\s]*$/, ''));
  const inputOfficeBlurHandler = () => setEnteredOfficeTouched(true);
  // Email Validation
  const enteredEmailIsValid = enteredEmail.trim() !== '' && Validator.isEmail(enteredEmail);
  const inputEmailIsValid = !enteredEmailIsValid && enteredEmailTouched;
  const inputEmailChangeHandler = (event) => setEnteredEmail(event.target.value);
  const inputEmailBlurHandler = () => setEnteredEmailTouched(true);
  // Mobile Validation
  const enteredMobileIsValid = enteredMobile.trim() !== '' && enteredMobile.length === 11;
  const inputMobileIsValid = !enteredMobileIsValid && enteredMobileTouched;
  const inputMobileChangeHandler = (event) =>
    setEnteredMobile(event.target.value.replace(/[^0-9]*$/, '').slice(0, 11));
  const inputMobileBlurHandler = () => setEnteredMobileTouched(true);
  // Message Validation
  const enteredMessageIsValid = enteredMessage.trim() !== '';
  const inputMessageIsValid = !enteredMessageIsValid && enteredMessageTouched;
  const inputMessageChangeHandler = (event) => setEnteredMessage(event.target.value);
  const inputMessageBlurHandler = () => setEnteredMessageTouched(true);

  let formIsValid = false;

  if (
    enteredNameIsValid &&
    enteredOfficeIsValid &&
    enteredEmailIsValid &&
    enteredMobileIsValid &&
    enteredMessageIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    if (formIsValid) {
      const data = await inquiry(
        enteredName,
        enteredEmail,
        enteredOffice,
        null,
        enteredMobile,
        null,
        null,
        enteredMessage,
        'sales_concern',
      );

      if (data.data.success === true) {
        setActive(true);
      }

      setEnteredName('');
      setEnteredNameTouched(false);
      setEnteredEmail('');
      setEnteredEmailTouched(false);
      setEnteredMobile('');
      setEnteredMobileTouched(false);
      setEnteredOffice('');
      setEnteredOfficeTouched(false);
      setEnteredMessage('');
      setEnteredMessageTouched(false);
    }
  };

  const closeOverlayHandler = () => {
    setActive(false);
  };

  if (active) setTimeout(() => setActive(false), 5000);

  return (
    <div className='form_sales_marketing'>
      <Form onSubmit={submitHandler}>
        <div className='form__header'>
          <span className='title'>We'd love to hear from you!</span>
          <div className='description'>
            <span>
              For sales and marketing concern, you can contact us by filling out this form.
            </span>
          </div>
        </div>
        <Row>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Name *'
            >
              <Form.Control
                type='text'
                value={enteredName}
                onInput={(event) => inputNameChangeHandler(event)}
                onBlur={() => inputNameBlurHandler()}
              />
              {inputNameIsValid && (
                <Form.Text className='text-danger'>Please provide your name.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Office / Agency  *'
            >
              <Form.Control
                type='text'
                value={enteredOffice}
                onInput={(event) => inputOfficeChangeHandler(event)}
                onBlur={() => inputOfficeBlurHandler()}
              />
              {inputOfficeIsValid && (
                <Form.Text className='text-danger'>Please provide your office / agency.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Email  *'
            >
              <Form.Control
                type='email'
                value={enteredEmail}
                onInput={(event) => inputEmailChangeHandler(event)}
                onBlur={() => inputEmailBlurHandler()}
              />
              {inputEmailIsValid && (
                <Form.Text className='text-danger'>Please provide a valid email address.</Form.Text>
              )}
            </FloatingLabel>
          </Col>
          <Col md='6'>
            <FloatingLabel
              controlId='floatingInput'
              label='Mobile  *'
            >
              <Form.Control
                type='text'
                value={enteredMobile}
                onInput={(event) => inputMobileChangeHandler(event)}
                onBlur={() => inputMobileBlurHandler()}
              />
              {inputMobileIsValid && (
                <Form.Text className='text-danger'>
                  Please provide an 11-digit mobile number.
                </Form.Text>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <FloatingLabel
          controlId='floatingInput'
          label='Inquiries  *'
          className='mb-5'
        >
          <Form.Control
            as='textarea'
            style={{ height: '180px' }}
            value={enteredMessage}
            onInput={(event) => inputMessageChangeHandler(event)}
            onBlur={() => inputMessageBlurHandler()}
          />
          {inputMessageIsValid && (
            <Form.Text className='text-danger'>Please provide your message.</Form.Text>
          )}
        </FloatingLabel>
        <Button
          className='btn'
          size='lg'
          type='submit'
          disabled={!formIsValid}
        >
          Send message
        </Button>
        {active && <InquiryOverlay closeOverlayHandler={closeOverlayHandler} />}
      </Form>
    </div>
  );
};

export default FormSalesMarketing;

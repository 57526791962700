// Packages
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import HomePage from '../src/pages/HomePage/HomePage';
import AboutUsPage from '../src/pages/AboutUsPage/AboutUsPage';
import NewsPage from '../src/pages/NewsPage/NewsPage';
import ViewArticlePage from './pages/ViewArticlePage/ViewArticlePage';
import ProductsPage from '../src/pages/ProductsPage/ProductsPage';
import ViewProductPage from './pages/ViewProductPage/ViewProductPage';
import LocationsPage from '../src/pages/LocationsPage/LocationsPage';
import ContactUsPage from '../src/pages/ContactUsPage/ContactUsPage';

// API

// CSS

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/about-us' element={<AboutUsPage />} />
        <Route exact path='/news' element={<NewsPage />} />
        <Route exact path='/news/:id' element={<ViewArticlePage />} />
        <Route exact path='/products-services' element={<ProductsPage />} />
        <Route exact path='/products-services/:id' element={<ViewProductPage />} />
        <Route exact path='/locations' element={<LocationsPage />} />
        <Route exact path='/contact-us' element={<ContactUsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// Packages
import { Accordion } from "react-bootstrap";

// Components

// API

// CSS
import "./LocationCard.css";

const LocationCard = (props) => {
  const { id, province, store } = props;

  return (
    <Accordion.Item eventKey={id}>
      <Accordion.Header className="locations_card__province">
        {province}
      </Accordion.Header>
      <Accordion.Body>
        {store &&
          store.map((item, index) => {
            return (
              <div key={index} className="locations__store">
                <h5 className="locations_card__store">{item.store_name}</h5>
                <div className="address__wrapper">
                  <svg viewBox="0 0 24 28.235">
                    <path
                      id="icons8_marker_1"
                      d="M19,4A11.992,11.992,0,0,0,9.889,23.8c.213.242,5.243,5.957,6.923,7.559a3.166,3.166,0,0,0,4.377,0c1.953-1.863,6.723-7.331,6.932-7.571A11.991,11.991,0,0,0,19,4Zm0,15.529A3.529,3.529,0,1,1,22.529,16,3.53,3.53,0,0,1,19,19.529Z"
                      transform="translate(-7 -4)"
                    />
                  </svg>
                  <span className="locations_card__address">
                    {item.address}
                  </span>
                </div>
                <div className="hours__wrapper">
                  <svg viewBox="0 0 24 24">
                    <path
                      id="icons8_clock"
                      d="M16,4A12,12,0,1,0,28,16,12.014,12.014,0,0,0,16,4Zm2.737,15.937a.9.9,0,0,1-1.273,0l-3-3A.9.9,0,0,1,14.2,16.3V9.7a.9.9,0,1,1,1.8,0v6.227l2.737,2.737A.9.9,0,0,1,18.737,19.937Z"
                      transform="translate(-4 -4)"
                    />
                  </svg>
                  <span className="locations_card__business_hours">
                    {item.office_hours}
                  </span>
                </div>
              </div>
            );
          })}
        {store && store.length === 0 && (
          <span className="locations__placeholder">
            No service centers available in this region.
          </span>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default LocationCard;

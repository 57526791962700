// Packages

// Components
import Main from '../Main/Main';
import Content from '../Content/Content';
import Topbar from '../Topbar/Topbar';
import Footer from '../Footer/Footer';

// API

// CSS
import './Layout.css';

const Layout = (props) => {
    return (
        <Main>
            <Topbar />
            <Content>
                {props.children}
            </Content>
            <Footer />
        </Main>
    );
};

export default Layout;
// Packages
import { useRef } from 'react';
import { Form, Button } from 'react-bootstrap';

// Components

// API
import { getSearchServiceCenter } from '../../../api/request';

// CSS
import './FormLocationSearch.css';

const FormLocationSearch = (props) => {
    const keywordRef = useRef();

    const submitHandler = async (event) => {
        event.preventDefault();
        const enteredKeyword = keywordRef.current.value;
        const data = await getSearchServiceCenter(enteredKeyword);
        props.getSearchResult(data.data);

        console.log("click");
    };

    const resetHandler = (event) => {
        if (event.target.value === "") {
            props.getSearchResult("");
            props.setActive(false);
        }
    };

    return (
        <Form className="form_location_search" onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formLocationSearch">
                <Form.Control type="text" placeholder="Search..." ref={keywordRef} size="lg" onChange={(event) => resetHandler(event)} />
                <Button className="btn" type="submit" size="lg">
                    <svg viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </Button>
            </Form.Group>
        </Form >
    );
};

export default FormLocationSearch;;
// Packages

// Components
import NewsCard from '../../NewsPage/NewsCard/NewsCard';
import convertDate from "../../../helpers/convertDate";


// API

// CSS
import './RelatedArticles.css';

const RelatedArticles = (props) => {
    const { relatedArticles } = props;

    return (
        <div className="related_articles">
            <span className="related_articles__title">Related Articles</span>
            <div className="related_articles__body">
                {relatedArticles && relatedArticles.map((item) => {
                    const convertedDate = convertDate(item.created_at);
                    return (
                        <NewsCard
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            date={convertedDate}
                            image={item.news_image_url_path}
                        />
                    );
                })}
                {!relatedArticles && (
                    <div className="news_list__placeholder">
                        <span className="text-black-50">No related articles found.</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RelatedArticles;
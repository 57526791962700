// Packages

// Components

// API

// CSS
import './Featured.css';

const Featured = (props) => {
    return (
        <div className="featured">
            <span className="featured__title">{props.title}</span>
            <div className="featured__body">
                {props.children}
            </div>
        </div>
    );
};

export default Featured;